<template>
  <div>
    <!-- Title -->
    <h2 class="font-bold text-xl mb-3.5">
      {{ l10nTitle }}
    </h2>

    <div>
      <div>
        <!-- Description -->
        <div class="mb-4">
          <p class="text-xl font-bold">
            {{ capitalize(t('validation.attributes.description')) }}
          </p>

          <p>
            {{ l10nDescription ? l10nDescription : t('ttmt.common.not_specified') }}
          </p>
        </div>

        <!-- Address -->
        <div class="mb-4">
          <p class="text-xl font-bold">
            {{ capitalize(t('validation.attributes.address')) }}
          </p>

          <app-data-grid-address
            :address="resource?.relationships?.address"
            :locale="selectedLocale"
          />
        </div>
      </div>

      <!-- Contact -->
      <div>
        <!-- Phone -->
        <div class="mb-4">
          <p class="text-xl font-bold">
            {{ capitalize(t('validation.attributes.phone')) }}
          </p>

          <app-data-grid-link
            type="phone"
            :link="resource?.attributes?.contact?.phone"
          />
        </div>

        <!-- Email -->
        <div class="mb-4">
          <p class="text-xl font-bold">
            {{ capitalize(t('validation.attributes.email')) }}
          </p>

          <app-data-grid-link
            type="email"
            :link="resource?.attributes?.contact?.email"
          />
        </div>

        <!-- Website -->
        <div class="mb-4">
          <p class="text-xl font-bold">
            {{ capitalize(t('validation.attributes.website')) }}
          </p>

          <app-data-grid-link
            :link="resource?.attributes?.contact?.website"
          />
        </div>
      </div>

      <!-- Link to main page -->
      <router-link
        :to="{ name: 'show-points-of-interest', params: { id: resource.id } }"
        class="app-link text-lg block mx-auto w-fit mt-5"
      >
        {{ t('ttmt.points_of_interest.pages.show.see_full_page') }}
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { capitalize } from 'lodash'

import usePointOfInterest from '@shared/hooks/resources/pointOfInterest'
import AppDataGridAddress from '@extranet/components/ui/data_grid/AppDataGridAddress.vue'
import AppDataGridLink from '@extranet/components/ui/data_grid/AppDataGridLink.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is it loading?
  loading: {
    type: Boolean,
    default: true,
  },
})

const { t, locale } = useI18n()
const {
  l10nTitle,
  l10nDescription,
} = usePointOfInterest(props)

const selectedLocale = ref(locale.value)
</script>
