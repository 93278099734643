<template>
  <!-- Explanations -->
  <div class="text-sm text-center">
    <p>
      {{ t('ttmt.traveller_folders.pages.qr_code_1') }}
    </p>
    <p class="mt-1">
      {{ t('ttmt.traveller_folders.pages.qr_code_2') }}
    </p>
  </div>

  <!-- QR Code -->
  <vue-qrcode
    ref="qrCodeRef"
    tag="img"
    :value="url"
    :options="{ width: 500 }"
    class="mx-auto w-44"
    @ready="adaptQrCodeSrc"
  />

  <!-- Download link -->
  <a
    v-if="qrCodeSrc"
    class="block text-center mx-auto app-link"
    :href="qrCodeSrc"
    :download="t('ttmt.documents.qrcode.filename')"
  >
    {{ t('ttmt.common.actions.download') }}
  </a>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import VueQrcode from '@chenfengyuan/vue-qrcode'

defineProps({
  // QR Code's URL
  url: {
    type: String,
    required: true,
  },
})

const { t } = useI18n()

// QR Code image source
const qrCodeSrc = ref()

// Adapt QR Code image source
function adaptQrCodeSrc(item) {
  qrCodeSrc.value = item.src
}
</script>
