<template>
  <app-table
    :headers="headers"
    :data="tableData"
    :loading="loading"
  />

  <app-pagination
    :links="links"
    :change-page-callback="handleChangePage"
    class="mt-5"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { capitalize } from 'lodash'

import { fetchInstitutionsEmployees as apiFetchInstitutionsEmployees } from '@shared/http/api'
import AppTable from '@extranet/components/ui/table/AppTable.vue'
import AppPagination from '@extranet/components/ui/AppPagination.vue'
import AppUserWithAvatar from '@extranet/components/ui/user/AppUserWithAvatar.vue'
import AppStatusBadge from '@shared/components/ui/AppStatusBadge.vue'
import PointOfInterestWithMainPicture from '@extranet/components/resources/point_of_interest/PointOfInterestWithMainPicture.vue'
import InstitutionEmployeeTableDates from '@extranet/components/resources/institution_employee/InstitutionEmployeeDates.vue'
import InstitutionEmployeeTableActions from '@extranet/components/resources/institution_employee/InstitutionEmployeeTableActions.vue'

const props = defineProps({
  // Table mode
  mode: {
    type: String,
    required: true,
    validator(value) {
      return [
        'owner',
        'employee',
      ].includes(value)
    },
  },
})

const emits = defineEmits([
  'approved',
  'declined',
  'canceled',
  'removed',
  'featured',
])

const { t } = useI18n()

// ---------- MODE ----------

const isModeOwner = computed(() => (
  props.mode === 'owner'
))

// ---------- INSTITUTIONS EMPLOYEES ----------

const institutionsEmployees = ref([])
const loading = ref(false)

function fetchInstitutionsEmployees() {
  loading.value = true

  const params = {
    'page': page.value,
    'mode': props.mode,
  }

  apiFetchInstitutionsEmployees(params)
    .then((response) => {
      institutionsEmployees.value = response.data.data ?? []
      // Pagination links
      links.value = response.data.meta?.links ?? []
    })
    .finally(() => {
      loading.value = false
    })
}

// ---------- ACTIONS EVENTS ----------

function handleDecline() {
  emits('declined')
  fetchInstitutionsEmployees()
}

function handleApprove() {
  emits('approved')
  fetchInstitutionsEmployees()
}

function handleRemove() {
  emits('removed')
  fetchInstitutionsEmployees()
}

function handleCancel() {
  emits('canceled')
  fetchInstitutionsEmployees()
}

function handleFeature() {
  emits('featured')
  fetchInstitutionsEmployees()
}

// ---------- DATA TABLE ----------

const headers = setHeaders()

function setHeaders() {
  const columns = []

  if (isModeOwner.value) {
    columns.push({ label: t('validation.attributes.employee') })
  }

  columns.push(
    { label: t('validation.attributes.institution') },
    { label: t('validation.attributes.state') },
    { label: t('validation.attributes.featured') },
    { label: t('validation.attributes.dates') },
    { label: t('validation.attributes.actions') },
  )

  return columns
}

const tableData = computed(() => (
  institutionsEmployees?.value?.map((institutionEmployee) => {
    const data = []

    if (isModeOwner.value) {
      data.push({
        employee: {
          component: AppUserWithAvatar,
          props: {
            resource: institutionEmployee.relationships?.user,
            withModal: true,
          },
        },
      })
    }

    data.push({
      institution: {
        component: PointOfInterestWithMainPicture,
        props: {
          resource: institutionEmployee.relationships?.point_of_interest,
          withModal: true,
        },
      },
      state: {
        component: AppStatusBadge,
        props: {
          text: t(`ttmt.institutions_employees.states.${institutionEmployee.attributes?.state}`),
          design: statusBadgeDesign(institutionEmployee.attributes?.state),
        },
      },
      featured: capitalize(institutionEmployee.attributes.featured ? t('ttmt.common.yes') : t('ttmt.common.no')),
      dates: {
        component: InstitutionEmployeeTableDates,
        props: {
          resource: institutionEmployee,
          isOwner: isModeOwner.value,
        },
      },
      actions: {
        component: InstitutionEmployeeTableActions,
        props: {
          resource: institutionEmployee,
        },
        events: {
          declined: handleDecline,
          approved: handleApprove,
          removed: handleRemove,
          canceled: handleCancel,
          featured: handleFeature,
        },
      },
    })

    return data.reduce((result, current) => (
      Object.assign(result, current)
    ))
  })
))

function statusBadgeDesign(state) {
  switch (state) {
    case 'pending':
    case 'invited':
      return 'warning'
    case 'approved':
      return 'success'
    case 'declined':
      return 'danger'
    default:
      return 'default'
  }
}

// ---------- PAGINATION ----------

const links = ref([])
const page = ref(1)

function handleChangePage(value) {
  page.value = value
  fetchInstitutionsEmployees()
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchInstitutionsEmployees()
})
</script>
