<template>
  <ul v-if="!isEmptyAddress">
    <li
      v-if="address.attributes.line1?.[localeToUse]"
    >
      {{ address.attributes.line1[localeToUse] }}
    </li>

    <li
      v-if="address.attributes.line2?.[localeToUse]"
    >
      {{ address.attributes.line2[localeToUse] }}
    </li>

    <li>
      <span
        v-if="address.attributes.zip"
        class="mr-1"
      >
        {{ address.attributes.zip }}
      </span>

      <span
        v-if="address.attributes.city?.[localeToUse]"
      >
        {{ address.attributes.city[localeToUse] }}
      </span>
    </li>

    <li
      v-if="address.attributes.dependent_locality?.[localeToUse]"
    >
      {{ address.attributes.dependent_locality[localeToUse] }}
    </li>

    <li
      v-if="address.attributes.administrative_area?.[localeToUse]"
    >
      {{ address.attributes.administrative_area[localeToUse] }}
    </li>

    <li
      v-if="address.attributes.country"
    >
      {{ countryCodeToFullName(address.attributes.country) }}
    </li>
  </ul>

  <p
    v-else
    class="italic"
  >
    {{ t('ttmt.common.not_specified') }}
  </p>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { isNil } from 'lodash'

import useGeoloc from '@shared/hooks/geoloc'

const props = defineProps({
  // Address object
  address: {
    type: Object,
    default: () => ({}),
  },
  // Locale to display the address
  locale: {
    type: String,
    default: null,
  },
})

const { countryCodeToFullName } = useGeoloc()
const { t, locale: appLocale } = useI18n()

const localeToUse = computed(() => (
  props.locale ?? appLocale.value
))

const isEmptyAddress = computed(() => (
  isNil(props.address?.attributes?.line1?.[localeToUse.value])
    && isNil(props.address?.attributes?.line2?.[localeToUse.value])
    && isNil(props.address?.attributes?.zip)
    && isNil(props.address?.attributes?.city?.[localeToUse.value])
    && isNil(props.address?.attributes?.dependent_locality?.[localeToUse.value])
    && isNil(props.address?.attributes?.administrative_area?.[localeToUse.value])
    && isNil(props.address?.attributes?.country)
))
</script>
