import { useI18n } from 'vue-i18n'

import useAccess from '@extranet/hooks/access'

export default function useInstitution() {
  const { t } = useI18n()

  const { institutionsAccess } = useAccess()
  /**
   * Breadcrumb title
   *
   * @returns {string}
   */
  function institutionBreadcrumbTitle() {
    return institutionsAccess.value
      ? t('ttmt.breadcrumbs.institutions.institutions')
      : t('ttmt.breadcrumbs.institutions.institution')
  }

  /**
   * Navbar title
   *
   * @returns {string}
   */
  function institutionNavbarTitle() {
    return institutionsAccess.value
      ? t('ttmt.extranet.navbar.my_institutions')
      : t('ttmt.extranet.navbar.my_institution')
  }

  return {
    institutionBreadcrumbTitle,
    institutionNavbarTitle,
  }
}
