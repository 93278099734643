import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useBreadcrumb from '@shared/hooks/breadcrumbs'
import useInstitution from '@extranet/hooks/institution'

export default function breadcrumbInstitution() {
  const { t } = useI18n()
  const { forceLink } = useBreadcrumb()
  const { institutionBreadcrumbTitle } = useInstitution()

  /**
   * Breadcrumb for index page
   *
   * @returns {[{link: boolean, text: string, to: string}]}
   */
  function dashboard() {
    return [
      {
        text: title.value,
        link: false,
        to: '/institutions',
      },
    ]
  }

  /**
   * Breadcrumb for create page
   *
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function create() {
    let breadcrumbs = dashboard()
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('ttmt.breadcrumbs.institutions.link'),
        link: false,
        to: null,
      },
      {
        text: t('ttmt.breadcrumbs.common.create_f'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  // ---------- TITLE ----------

  const title = computed(() => (
    institutionBreadcrumbTitle()
  ))

  return {
    dashboard,
    create,
  }
}
