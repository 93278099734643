<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <div class="max-w-md">
      <div
        v-if="!poiLoading"
        ref="poiTableContainerRef"
      >
        <app-section-title
          :title="t('ttmt.institutions.index.sections.validated_claims')"
          class="mb-4"
        />

        <!-- Institutions list -->
        <template
          v-if="pointsOfInterest.length > 0"
        >
          <app-card-item
            v-for="(pointOfInterest, i) in pointsOfInterest"
            :key="i"
            class="my-4 p-4"
          >
            <!-- Institution details -->
            <point-of-interest-card
              v-bind="pointOfInterest"
            />

            <!-- QR Code -->
            <institutions-qr-code
              :poi="pointOfInterest"
            />
          </app-card-item>
        </template>

        <p
          v-else
          class="mt-6"
        >
          {{ t('ttmt.extranet.institution.messages.no_records') }}
        </p>
      </div>

      <!-- Empty cards loading -->
      <template
        v-else
      >
        <point-of-interest-card-empty
          v-for="i in 3"
          :key="i"
          class="my-4"
        />
      </template>

      <!-- Pagination -->
      <app-pagination
        :links="poiLinks"
        :change-page-callback="handlePoiChangePage"
        :element-to-scroll-to="poiTableContainerRef"
        class="mt-5"
      />
    </div>

    <!-- Claims -->
    <div>
      <app-section-title
        :title="t('ttmt.institutions.index.sections.pending_claims')"
        class="mb-4"
      />

      <div ref="claimTableContainerRef">
        <app-table
          :headers="claimsHeaders"
          :data="claimsData"
          :loading="claimLoading"
        />
      </div>

      <app-pagination
        :links="claimLinks"
        :change-page-callback="handleClaimChangePage"
        :element-to-scroll-to="claimTableContainerRef"
        class="mt-5"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  fetchOwnedPointsOfInterest,
  fetchPendingClaims,
} from '@shared/http/api'
import useClaim from '@extranet/hooks/claim'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppPagination from '@extranet/components/ui/AppPagination.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppStatusBadge from '@shared/components/ui/AppStatusBadge.vue'
import AppTable from '@extranet/components/ui/table/AppTable.vue'
import ClaimTableActions from '@extranet/components/resources/claim/ClaimTableActions.vue'
import InstitutionsQrCode from '@extranet/components/institutions/InstitutionsQrCode.vue'
import PointOfInterestCard from '@extranet/components/resources/point_of_interest/PointOfInterestCard.vue'
import PointOfInterestCardEmpty from '@extranet/components/resources/point_of_interest/PointOfInterestCardEmpty.vue'
import PointOfInterestWithMainPicture from '@extranet/components/resources/point_of_interest/PointOfInterestWithMainPicture.vue'

const { t } = useI18n()

// ---------- POINTS OF INTEREST ----------

const poiNextPageFetching = ref(false)
const pointsOfInterest = ref([])
const poiLoading = ref(true)

function fetchPois(nextPage = false) {
  if (nextPage === false) {
    poiLoading.value = true
  }

  poiNextPageFetching.value = nextPage

  const params = {
    'page': poiPage.value,
  }

  fetchOwnedPointsOfInterest(params)
    .then((response) => {
      pointsOfInterest.value = response.data.data?.map((pointOfInterest) => ({
        resource: pointOfInterest,
      })) ?? []

      // Pagination links
      poiLinks.value = response.data.meta?.links ?? []
    })
    .finally(() => {
      poiNextPageFetching.value = false
      poiLoading.value = false
    })
}

// ---------- POI PAGINATION ----------

const poiTableContainerRef = ref(null)
const poiLinks = ref([])
const poiPage = ref(1)

function handlePoiChangePage(value) {
  poiPage.value = value
  fetchPois()
}

// ---------- CLAIMS ----------

const { statusBadgeDesign } = useClaim()

const claimNextPageFetching = ref(false)
const claims = ref([])
const claimLoading = ref(true)

function fetchClaims(nextPage = false) {
  if (nextPage === false) {
    claimLoading.value = true
  }

  claimNextPageFetching.value = nextPage

  const params = {
    'page': claimPage.value,
  }

  fetchPendingClaims(params)
    .then((response) => {
      claims.value = response.data.data?.map((claim) => ({
        resource: claim,
      })) ?? []

      // Pagination links
      claimLinks.value = response.data.meta?.links ?? []
    })
    .finally(() => {
      claimNextPageFetching.value = false
      claimLoading.value = false
    })
}

const claimsHeaders = [
  { label: t('validation.attributes.point_of_interest') },
  { label: t('validation.attributes.state') },
  { label: t('validation.attributes.actions') },
]

const claimsData = computed(() => (
  claims.value.map((claim) => ({
    poi: {
      component: PointOfInterestWithMainPicture,
      props: {
        resource: claim?.resource?.relationships?.point_of_interest,
      },
    },
    state: {
      component: AppStatusBadge,
      props: {
        text: t(`ttmt.states.claim_state.${claim?.resource?.attributes?.state}`),
        design: statusBadgeDesign(claim?.resource?.attributes?.state),
      },
    },
    actions: {
      component: ClaimTableActions,
      props: {
        resource: claim?.resource,
      },
    },
  }))
))

// ---------- CLAIM PAGINATION ----------

const claimTableContainerRef = ref(null)
const claimLinks = ref([])
const claimPage = ref(1)

function handleClaimChangePage(value) {
  claimPage.value = value
  fetchClaims()
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchPois()
  fetchClaims()
})
</script>
